<template>
  <div class="section-container signin">
    <v-container>
      <v-row class="signin">
        <v-col cols="12" md="12" class="right text-center">
          <img
            src="@/assets/images/Full-Logo-Gold.png"
            class="site-logo mb-5"
          />
          <div class="inner">
            <h2>Login</h2>
            <validation-observer ref="loginObserver" v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(login)" ref="loginForm">
                <v-row>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                      mode="lazy"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        v-model="email"
                        class="form-control"
                        :error-messages="errors"
                        placeholder="Email"
                        required
                        outlined
                        dark
                        background-color="#f3f3f3"
                        color="#000"
                        data-vv-validate-on="change"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        v-model="password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        class="form-control"
                        :error-messages="errors"
                        placeholder="Password"
                        required
                        outlined
                        dark
                        background-color="#f3f3f3"
                        color="#000"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </validation-provider>
                    <div
                      class="forgot-password my-3 text-right font-weight-bold"
                    >
                      <router-link to="/forgot-password"
                        >Forgot password?</router-link
                      >
                    </div>
                  </v-col>
                </v-row>

                <div class="text-center">
                  <v-btn
                    class="signin-btn"
                    type="submit"
                    color="primary"
                    dark
                    rounded
                    :disabled="loading"
                    :loading="loading"
                  >
                    Sign In
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions } from "vuex";
import { redirectPageName } from "@/utils/helpers";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Footer,
  },
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions("auth", ["authRequest"]),
    async login() {
      this.loading = true;
      this.authRequest({
        userName: this.email,
        password: this.password,
      })
        .then(() => {
          this.loading = false;
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "LoggedIn Successfully",
              type: "success",
            },
            { root: true }
          );
          this.$router.push({ name: redirectPageName() });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: error.response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        });
    },
    resetLogin() {
      this.$refs.loginObserver.reset();
      this.$refs.loginForm.reset();
    },
  },
};
</script>
